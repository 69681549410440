import React, { useState, useEffect } from 'react';
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { IoMdSearch } from "react-icons/io";

const SuperadminDashboard = () => {
  const [partners, setPartners] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isAdmin, setIsAdmin] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState('');
  const [sortOrder, setSortOrder] = useState(''); // State to store sorting order

  const recordsPerPage = 8;
  const apiUrl = process.env.REACT_APP_BASE_URL; // Use the environment variable

  useEffect(() => {
    const fetchPartners = async () => {
      try {
        const sessionToken = await fetchSessionToken();
        const role = localStorage.getItem('role');

        if (sessionToken && (role === 'admin' || role === 'finance')) {
          setIsAdmin(true);
      

          const response = await fetch(`${apiUrl}ESIMGOAdmin/partner_details?flag=1`, {
            headers: {
              'Authorization': `Bearer ${sessionToken}`
            }
          });

          if (!response.ok) {
            throw new Error('Failed to fetch data');
          }

          const data = await response.json();
          setPartners(data.partnerlist);
        } else {
          setIsAdmin(false);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        // Handle error state if needed
      } finally {
        setLoading(false);
      }
    };

    fetchPartners();
  }, []);

  const fetchSessionToken = async () => {
    let sessionToken = localStorage.getItem('token');
    // Your session token fetch logic here
    return sessionToken;
  };
  
  const handleSortChange = (e) => {
    setSortOrder(e.target.value); // Update sorting order based on selection
  };
  // Handle search input change
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  // Filter partners based on search query
  const filteredPartners = partners.filter(partner =>
    partner.partnercode.toLowerCase().includes(searchQuery.toLowerCase()) ||
    partner.contactperson.toLowerCase().includes(searchQuery.toLowerCase())
  )
  .sort((a, b) => {
    if (sortOrder === 'asc') {
      return a.balance - b.balance; // Low to high
    } else if (sortOrder === 'desc') {
      return b.balance - a.balance; // High to low
    } else {
      return 0; // No sorting applied
    }
  });

  // Calculate the indices for slicing the data
  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = filteredPartners.slice(indexOfFirstRecord, indexOfLastRecord);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!isAdmin) {
    return <div>You do not have permission to view this page.</div>;
  }

  return (
    <div className="container mx-auto p-8 bg-white rounded-2xl">




<div className="flex justify-between items-center mb-4">
  {/* Left side - Title */}
  <h1 className="text-5xl font-bold">Dashboard</h1>
 
  {/* Right side - Button and Search */}
  <div className="flex items-center space-x-4">
    {/* Button to download CSV */}
    <select value={sortOrder} onChange={handleSortChange}          className="px-4 py-2 bg-gray-200 text-black rounded-lg"      >
        <option value="">Select Sorting </option>
        <option value="asc">Low to High</option>
        <option value="desc">High to Low</option>
      </select>
    {/* Search Input */}
     <div className="relative">
    
     <input
            type="text"
            placeholder="Search by Partner Code or Name"
            value={searchQuery}
            onChange={handleSearchChange}
            className="border-2 bg-[#7bc2c5] rounded-full p-2 pl-10 w-96 text-[#105255] placeholder-[#105255]"  
             />
  <div className="absolute inset-y-0 left-0 flex items-center pl-3">
    <IoMdSearch size={20} className="text-[#105255]" />
  </div>
</div>

  </div>
</div>
      <div className="flex flex-col">
        <table className="min-w-full bg-white">
          <thead>
            <tr>
              <th className="border-b text-center px-4 py-2">Partner Code</th>
              <th className="border-b text-center px-4 py-2">Partner Name</th>
              <th className="border-b text-center px-4 py-2">Wallet Amount</th>
            </tr>
          </thead>
          <tbody>
            {currentRecords.map((partner) => (
              <tr key={partner.partnerid}>
                <td className="border-b text-center px-4 py-2">{partner.partnercode}</td>
                <td className="border-b text-center px-4 py-2">{partner.companyname}</td>
                <td className="border-b text-center px-4 py-2 text-[#3a9a8a]">
                  ${partner.balance}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="mb-4 flex justify-between">
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
          className="px-4 py-2 bg-gray-300"
        >
          <FaChevronLeft />
        </button>
        <button
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentRecords.length < recordsPerPage}
          className="px-4 py-2 bg-gray-300"
        >
          <FaChevronRight />
        </button>
      </div>
    </div>
  );
};

export default SuperadminDashboard;