import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";
import { Link } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import tick from "../assets/images/ticksignup.svg";
import { ImCross } from "react-icons/im";
import Modal from "react-modal";
import TermsPrivacy from "./Terms&Privacy";
import TermsUse from "./Terms&Use";

function ModalSignup({ isOpen, message, onClose }) {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white rounded-lg p-6 shadow-lg max-w-sm w-full relative">
        <button
          onClick={onClose}
          className="absolute top-4 right-4 px-4 py-2  text-white rounded-lg hover:bg-[#20bfb2]"
        >
          <ImCross size={20} color="grey" />
        </button>
        <div className="flex flex-col items-center justify-center h-full">
          <img src={tick} alt="ticksignup" className="mb-4" />
          <p className="text-5xl font-bold">{message}</p>
        </div>
      </div>
    </div>
  );
}

function SignUp() {
  const navigate = useNavigate();
  const [number, setNumber] = useState("");
  const [countryCode, setCountryCode] = useState("+91");
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    fullName: "",
    phoneNumber: "",
    emailId: "",
    password: "",
    companyName: "",
    address: "",
    countryId: "+91",
    stateId: "0",
    cityId: 0,
    zipCode: "0",
    p_type: "1",
    website:"",
  });
  const [errors, setErrors] = useState({});
  const [agreeTerms, setAgreeTerms] = useState(false);
  const [agreePrivacy, setAgreePrivacy] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [showPassword, setShowPassword] = useState(false); // State to toggle password visibility
  const [isPrivacyModalOpen, setIsPrivacyModalOpen] = useState(false);
  const [isTermsModalOpen, setIsTermsModalOpen] = useState(false);

  // Functions to open/close Privacy modal
  const handlePrivacyOpen = () => setIsPrivacyModalOpen(true);
  const handlePrivacyClose = () => setIsPrivacyModalOpen(false);

  // Functions to open/close Terms of Use modal
  const handleTermsOpen = () => setIsTermsModalOpen(true);
  const handleTermsClose = () => setIsTermsModalOpen(false);
  const apiUrl = process.env.REACT_APP_BASE_URL; // Use the environment variable

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.id]: e.target.value });
  };

  const handleCheckboxChange = (e) => {
    const { id, checked } = e.target;
    if (id === "agreeTerms") {
      setAgreeTerms(checked);
    } else if (id === "agreePrivacy") {
      setAgreePrivacy(checked);
    }
  };

  const validateForm = () => {
    const errors = {};
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const passwordPattern =
      /^(?=.*[!@#\$%\^&\*])(?=.*[a-zA-Z])(?=.*[0-9]).{8,}$/;
    if (!formData.firstName) {
      errors.firstName = "First name is required";
    }

    if (!formData.lastName) {
      errors.lastName = "Last name is required";
    }
    if (!formData.emailId) {
      errors.emailId = "Email is empty";
    } else if (!emailPattern.test(formData.emailId)) {
      errors.emailId = "Email is invalid";
    }
    if (!formData.companyName) {
      errors.companyName = "Company Name is required";
    }
    if (!formData.password) {
      errors.password = "Password is required";
    } else if (!passwordPattern.test(formData.password)) {
      errors.password =
        "Password must be at least 8 characters long and include at least one letter, one number, and one special character (@, #, etc.)";
    }

    if (!agreeTerms) {
      errors.agreeTerms = "You must agree to the terms and policy";
    }

    if (!agreePrivacy) {
      errors.agreePrivacy = "You must agree to the privacy policy";
    }

    setErrors(errors);

    return Object.keys(errors).length === 0;
  };

  const handleLoginClick = () => {
    if (!validateForm()) {
      return;
    }

    const fullName = `${formData.firstName} ${formData.lastName}`;

    const payload = {
      ...formData,
      fullName,
      phoneNumber: number,
      countryId: countryCode,
      cityId: parseInt(formData.cityId),
    };
    delete payload.firstName;
    delete payload.lastName;
    // alert(JSON.stringify({ fullName, ...payload }, null, 2));
    fetch(`${apiUrl}ESIM/RegisterPartner`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          setModalMessage("Your Account Has Been Created Successfully");
          setModalOpen(true);
          setTimeout(() => {
            navigate("/");
          }, 2000); // Close modal after 2 seconds and navigate
        } else {
          setModalMessage( data.message);
          setModalOpen(true);
        }
      })
      .catch((error) => {
        console.error("API Error:", error);
        setModalMessage("API Error: " + error.message);
        setModalOpen(true);
      });
  };

  const handlePhoneChange = (value, data) => {
    setNumber(value);
    setCountryCode(data.dialCode);
    setFormData({ ...formData, phoneNumber: value });
  };

  return (
    <>
      <ModalSignup
        isOpen={modalOpen}
        message={modalMessage}
        onClose={() => setModalOpen(false)}
      />

      <div className="login w-full flex justify-center items-center p-9">
        <div
          className="rounded-2xl mb-12 md:mb-0 md:w-8/12 lg:w-5/12 xl:w-7/12 xl:p-6 dark:bg-gray-800 dark:border-gray-700 p-6"
          style={{ backgroundColor: "#ffffff" }}
        >
          <div className="flex items-center mb-8 justify-center font-bold text-5xl">
            <div className="arrow h-16 w-16 flex justify-center items-center rounded-full bg-transparent shadow-md border-2 border-green-200">
              <Link to="/Home" className="text-2xl">
                <FaArrowLeft />
              </Link>
            </div>
            <h1 className="font-semibold text-5xl ml-4">Create an Account</h1>
          </div>
          <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full px-3">
              <label
                className="flex uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 rounded-full"
                htmlFor="grid-emailId"
              >
                Email<p className="flex text-red-500">*</p>
              </label>
              <input
                style={{
                  backgroundColor: "#ffffff",
                  border: "1px solid black",
                }}
                className="appearance-none block w-full bg-black-200 text-gray-700 border border-black-200 rounded-full py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="emailId"
                type="email"
                placeholder="Enter Email"
                value={formData.emailId}
                onChange={handleInputChange}
              />
              {errors.emailId && (
                <p className="text-red-500 text-xs italic">{errors.emailId}</p>
              )}
            </div>
          </div>
          <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
              <label
                className="flex uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor="grid-first-name"
              >
                First Name<p className="flex text-red-500">*</p>
              </label>
              <input
                style={{
                  backgroundColor: "#ffffff",
                  border: "1px solid black",
                }}
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded-full py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                id="firstName"
                type="text"
                placeholder="First Name"
                value={formData.firstName}
                onChange={handleInputChange}
              />
              {errors.firstName && (
                <p className="text-red-500 text-xs italic">
                  {errors.firstName}
                </p>
              )}
            </div>
            <div className="w-full md:w-1/2 px-3">
              <label
                className="flex uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor="grid-last-name"
              >
                Last Name<p className="flex text-red-500">*</p>
              </label>
              <input
                style={{
                  backgroundColor: "#ffffff",
                  border: "1px solid black",
                }}
                className="appearance-none block w-full bg-gray-800 text-gray-700 border border-gray-200 rounded-full py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-red-500"
                id="lastName"
                type="text"
                placeholder="Last Name"
                value={formData.lastName}
                onChange={handleInputChange}
              />
              {errors.lastName && (
                <p className="text-red-500 text-xs italic">{errors.lastName}</p>
              )}
            </div>
          </div>
          <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full md:w-1/2 px-3">
              <label
                className="flex uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor="grid-company-name"
              >
                Company Name<p className="flex text-red-500">*</p>
              </label>
              <input
                style={{
                  backgroundColor: "#ffffff",
                  border: "1px solid black",
                }}
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded-full py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="companyName"
                type="text"
                placeholder="Company Name"
                value={formData.companyName}
                onChange={handleInputChange}
              />
              {errors.companyName && (
                <p className="text-red-500 text-xs italic">{errors.companyName}</p>
              )}
            </div>
            <div className="w-full md:w-1/2 px-3">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor="grid-phone-number"
              >
                Phone Number
              </label>
              <PhoneInput
                country={"us"}
                value={number}
                onChange={handlePhoneChange}
                containerClass="custom-phone-input-container"
                inputClass="custom-phone-input"
                buttonClass="custom-phone-button"
                dropdownClass="custom-dropdown"
                inputStyle={{
                  backgroundColor: "#ffffff",
                  border: "1px solid black",
                  appearance: "none",
                  width: "100%",
                  borderRadius: "9999px",
                  padding: "1.5rem 3rem",
                  lineHeight: "1.25",
                  outline: "none",
                }}
                buttonStyle={{
                  borderTopLeftRadius: "9999px",
                  borderBottomLeftRadius: "9999px",
                }}
              />
            </div>
          </div>
          <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full md:w-1/2 px-3">
              <label
                className="flex uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                Password<p className="flex text-red-500">*</p>
              </label>
              <input
                style={{
                  backgroundColor: "#ffffff",
                  border: "1px solid black",
                }}
                className="appearance-none block w-full bg-black-200 text-gray-700 border border-black-200 rounded-full py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="password" // Unique id
                type={showPassword ? "text" : "password"}
                placeholder="Enter Password"
                value={formData.password}
                onChange={handleInputChange}
                autoComplete="new-password" // Disable autofill
              />
              {errors.password && (
                <p className="text-red-500 text-xs italic">{errors.password}</p>
              )}
            </div>

            <div className="w-full md:w-1/2 px-3">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor="grid-website"
              >
                Website
              </label>
              <input
                style={{
                  backgroundColor: "#ffffff",
                  border: "1px solid black",
                }}
                className="appearance-none block w-full bg-black-200 text-gray-700 border border-black-200 rounded-full py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="website"
                type="text"
                placeholder="Enter Website"
                value={formData.website}
                onChange={handleInputChange}
              />
            </div>
          </div>

          <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full px-3">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor="grid-address"
              >
                Billing Address
              </label>
              <input
                style={{
                  backgroundColor: "#ffffff",
                  border: "1px solid black",
                }}
                className="appearance-none block w-full bg-black-200 text-gray-700 border border-black-200 rounded-full py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="address"
                type="text"
                placeholder="Enter Address"
                value={formData.address}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full px-3">
              <input
                className="mr-2 leading-tight"
                id="agreeTerms"
                type="checkbox"
                checked={agreeTerms}
                onChange={handleCheckboxChange}
              />

              <span className="text-sm">
                <button
                  onClick={() => handleTermsOpen()}
                  className="text-blue-500 underline ml-2 bg-transparent border-none cursor-pointer"
                  type="button"
                >
                  I agree to the Terms and Condition
                </button>
              </span>

              <span className="text-sm"></span>

              {errors.agreeTerms && (
                <p className="text-red-500 text-xs italic">
                  {errors.agreeTerms}
                </p>
              )}
            </div>
          </div>
          <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full px-3">
              <input
                className="mr-2 leading-tight"
                id="agreePrivacy"
                type="checkbox"
                checked={agreePrivacy}
                onChange={handleCheckboxChange}
              />

<span className="text-sm">
      <button
        onClick={handlePrivacyOpen}
        className="text-blue-500 underline ml-2 bg-transparent border-none cursor-pointer"
        type="button"
      >
        I agree to the Privacy policy
      </button>
    </span>

              {errors.agreePrivacy && (
                <p className="text-red-500 text-xs italic">
                  {errors.agreePrivacy}
                </p>
              )}
            </div>
          </div>
          <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full px-3">
              <button
                onClick={handleLoginClick}
                className="rounded-full m-2 text-white w-full px-4 py-4 shadow-md hover:bg-white transition duration-200 ease-in"
                style={{
                  background:
                    "linear-gradient(to bottom right, #27d0b6, #24c8b5, #1bb1af, #18a8ad)",
                }}
              >
                Create Account
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Privacy Modal */}
      <Modal
        isOpen={isPrivacyModalOpen}
        onRequestClose={handlePrivacyClose}
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          },
          content: {
            width: "40%",
            margin: "auto",
            padding: "0px",
            borderRadius: "8px",
            height: "80%",
          },
        }}
      >
        <TermsPrivacy closeModal={handlePrivacyClose} />
      </Modal>

      {/* Terms of Use Modal */}
      <Modal
        isOpen={isTermsModalOpen}
        onRequestClose={handleTermsClose}
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          },
          content: {
            width: "40%",
            margin: "auto",
            padding: "0px",
            borderRadius: "8px",
            height: "80%",
          },
        }}
      >
        <TermsUse closeModal={handleTermsClose} />
      </Modal>
    </>
  );
}

export default SignUp;
